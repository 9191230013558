<template>
  <div class="app-container">
    <CrudTable ref="table" entity="Translation" :columns="columns" :allow-create="false" :allow-delete="false" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import crudIndexMixin from '@/mixins/crud-index';
export default {
  components: {
    CrudTable
  },
  mixins: [crudIndexMixin],
  data() {
    return {
      columns: [{ field: 'code', label: 'translation.code' }, 'description']
    };
  }
};
</script>

<style scoped></style>
